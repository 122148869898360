<template>
  <div v-if="!now === false || this.$route.name === 'login'">
    <navbar-component />
    <h3 v-if="this.$route.name !== 'login'" class="zen__m__title">
      {{ this.$route.name }}
    </h3>
    <section class="zen__own__container">
      <router-view class="zen__container" v-bind:now="this.now" />
    </section>
  </div>
</template>

<script>
import { CheckSession } from "@/api/user/user.api";
import "./styles/zen_main__ui__kit__chunk__1.css";
import "./styles/zen_main__ui__kit__chunk__2.css";
import "./styles/zen_main__ui__kit__chunk__3.css";
import "./styles/zen_main_ui__kit__responsive.css";

export default {
  components: {
    navbarComponent: () => import("./components/Navbar/Navbar.component.vue"),
  },
  data() {
    return { now: null };
  },
  mounted() {
    window.addEventListener("storage", () => {
      this.$router.push("/login");
      localStorage.clear();
    });
    setTimeout(() => this.checkSessionHandler(), 200);
  },
  watch: {
    async $route() {
      try {
        if (this.$route.name === "Пользователи") {
          this.now = await (await CheckSession()).result.now;
        }
        document.body.setAttribute("style", "overflow-y: scroll");
      } catch (e) {
        console.log("access denied");
      }
    },
  },
  methods: {
    async checkSessionHandler() {
      if (this.$route.name === "login" || this.$route.name === null) {
        return void 0;
      }
      this.now = await (await CheckSession()).result.now;
    },
  },
  created() {
    setInterval(() => this.checkSessionHandler(), 60_000);
  },
};
</script>
