<template>
  <div v-if="!now === false || this.$route.name === 'login'">
    <SideBar
      v-bind:changeType="changeType"
      v-bind:action="action"
      v-bind:show="show"
      v-bind:type="type"
    />
    <div
      v-if="show"
      v-bind:style="show && 'width: 100%;'"
      class="zen__sidebar__blackout"
      v-on:click="action()"
    />
    <section class="zen__own__container">
      <router-view
        class="zen__container"
        v-bind:now="this.now"
        v-bind:type="type"
        v-bind:show="show"
      />
    </section>
  </div>
</template>

<script>
import { CheckSession } from "@/api/user/user.api";
import "./styles/zen_main__ui__kit__chunk__1.css";
import "./styles/zen_main__ui__kit__chunk__2.css";
import "./styles/zen_main__ui__kit__chunk__3.css";

export default {
  components: {
    SideBar: () => import("./searh_componets/sidebar/SideBar.component.vue"),
  },
  data() {
    return { now: null, type: "phone", show: false };
  },
  mounted() {
    window.addEventListener("storage", () => {
      this.$router.push("/login");
      localStorage.clear();
    });
    setTimeout(() => this.checkSessionHandler(), 200);
  },
  watch: {
    async $route() {
      try {
        this.type = this.$route.name;
      } catch (e) {
        console.log("access denied");
      }
    },
  },
  methods: {
    async checkSessionHandler() {
      if (this.$route.name === "login" || this.$route.name === null) {
        return void 0;
      }
      this.now = await (await CheckSession()).result.now;
    },
    action() {
      this.show = !this.show;
      setTimeout(() => {
        Array.from(document.querySelectorAll(".zen__search__menu__title")).map(
          (el) => {
            el.classList.toggle("zen__search__menu__title__show");
          }
        );
      }, 150);
    },
    changeType(value) {
      this.type = value;
      this.$router.push("/" + value);
    },
  },
  created() {
    setInterval(() => this.checkSessionHandler(), 60_000);
  },
};
</script>
