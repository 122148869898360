import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Пользователи",
    component: () => import("@/views/AdminTableView.vue"),
  },
  {
    path: "/sessions",
    name: "Сессии",
    component: () => import("@/views/SessionView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: "/config",
    name: "Конфигурации",
    component: () => import("@/views/ConfigView.vue"),
  },
  {
    path: "/source",
    name: "Источники",
    component: () => import("@/views/SourceView.vue"),
  },
  {
    path: "/lock",
    name: "Блокировки",
    component: () => import("@/views/LockView.vue"),
  },
  {
    path: "/userconfig/:id",
    name: "Конфигурация",
    props: true,
    component: () => import("@/views/UserConfigView.vue"),
  },
  {
    path: "/stats",
    name: "Статистика",
    props: true,
    component: () => import("@/views/StatsView.vue"),
  },
];

const UserRoutes: Array<RouteConfig> = [
  {
    path: "/social/:id",
    name: "медиа",
    props: true,
    component: () => import("@/views/SocialView.vue"),
  },
  {
    path: "*",
    name: "Главная",
    component: () => import("@/views/SearchView.vue"),
  },
  {
    path: "/phone",
    name: "phone",
    component: () => import("@/views/SearchPhoneView.vue"),
  },
  {
    path: "/email",
    name: "email",
    component: () => import("@/views/SearchEmailView.vue"),
  },
  {
    path: "/name",
    name: "name",
    component: () => import("@/views/SearchNameView.vue"),
  },
  {
    path: "/explanation-search",
    name: "explanation search",
    component: () => import("@/views/SearchExplanationView.vue"),
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
  },
];

export const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export const userRouter = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes: UserRoutes,
});
