import {
  IApiProperties,
  ProductionURL,
  DevelopmentURL,
  relativeUrl,
} from "../types/type";

export class PrefireModule {
  async fire(
    rootUrl: ProductionURL | DevelopmentURL,
    relativeUrl: relativeUrl,
    properties: IApiProperties
  ): Promise<Response> {
    const response: Response = await fetch(rootUrl + relativeUrl, properties);

    if (response.status === 401) {
      window.location.href = "#/login";
    }

    return response;
  }
}
