import {
  ProductionURL,
  DevelopmentURL,
  relativeUrl,
  headers,
  body,
  method,
} from "../types/type";

export class LoggerModule {
  private getChromium() {
    const isChrome = navigator.userAgent.match(/Chrom(e|ium)\/([0-9]+)\./);
    return isChrome ? parseInt(isChrome[2], 10) : false;
  }
  public request(
    name: string,
    root: ProductionURL | DevelopmentURL,
    url: relativeUrl,
    method: method,
    headers: headers,
    body: body,
    status: number | undefined,
    response: Response,
    query: any[] | undefined = undefined
  ) {
    console.groupCollapsed(name);
    console.group("DESCRIPTION");
    console.log("USER AGENT:", navigator.userAgent);
    console.log("NOW:", new Date(Date.now()));
    console.log("CHROMIUM:", this.getChromium());
    console.log("MODE:", process.env.NODE_ENV);
    console.groupEnd();

    console.group("ORIGIN");
    console.info(
      "ORIGIN %c%s",
      "font-size: 10px; font-weight: 600;",
      root + url
    );
    console.log("HOST", root);
    console.groupEnd();

    console.group("REQUEST");
    console.log("REQUEST TO:", `/${url}`);
    console.log("QUERY", query);
    console.log("METHOD", method ? method : "GET");
    console.log("STATUS", status);
    console.log("HEADERS", headers);
    console.log("BODY", body);
    console.groupEnd();

    console.group("RESPONSE");
    console.log(response);
    console.groupEnd();

    console.groupEnd();
  }
}
