import {
  IApiProperties,
  IApiService,
  ProductionURL,
  DevelopmentURL,
  relativeUrl,
  headers,
  method,
  body,
  query,
} from "./types/type";

import { PropertiesModule } from "./modules/properties";
import { ConnectModule } from "./modules/connect";
import { PrefireModule } from "./modules/prefire";
import { LoggerModule } from "./modules/logger";
import { StorageRequest } from "./modules/save";
import { IDModule } from "./modules/id";

export class ApiService {
  private readonly rootUrl: ProductionURL | DevelopmentURL =
    new ConnectModule().validate();
  private properties: IApiProperties;
  private readonly relativeUrl: relativeUrl;
  private readonly headers: headers;
  private readonly isAuth: boolean;
  private readonly method: method;
  private readonly query: query;
  private readonly body: body;

  constructor(
    { body, relativeUrl, headers, method, query }: IApiService,
    isAuth: boolean = false
  ) {
    this.relativeUrl = relativeUrl;
    this.headers = headers;
    this.properties = {};
    this.method = method;
    this.isAuth = isAuth;
    this.query = query;
    this.body = body;
  }

  public async request(): Promise<Response> {
    const id = new IDModule().make(28);

    const requestInfo = `API services | ${
      this.relativeUrl === "auth" ? "temporary" : "saved"
    } | request ID: ${id.substring(0, 10)} | ${
      this.relativeUrl
    } | request time`;

    this.properties = new PropertiesModule().validate(
      this.isAuth,
      this.headers,
      this.method,
      this.body
    );
    console.time(requestInfo);

    const response: Response = await (
      await new PrefireModule().fire(
        this.rootUrl,
        this.relativeUrl,
        this.properties
      )
    ).json();

    if (this.relativeUrl !== "auth") {
      new StorageRequest().push(
        {
          rootUrl: this.rootUrl,
          relativeUrl: this.relativeUrl,
          method: this.properties.method,
          headers: this.properties.headers,
          body: this.properties.body,
          status: response.status,
          query: this.query,
          response,
        },
        id
      );
    }

    new LoggerModule().request(
      "Api Service",
      this.rootUrl,
      this.relativeUrl,
      this.properties.method,
      this.properties.headers,
      this.properties.body,
      response.status,
      response,
      this.query
    );

    console.timeEnd(requestInfo);
    return response;
  }
}
