import { request, storageItem } from "../types/type";

export class StorageRequest {
  private storage: storageItem[] = [];
  push(request: request, requestId: string): void {
    if (sessionStorage.getItem("__Storage__Request__") !== null) {
      this.storage = JSON.parse(
        sessionStorage.getItem("__Storage__Request__")!
      );
    }

    if (this.storage.length >= 20) {
      const item = this.storage.splice(0, 1);
      console.groupCollapsed(
        "Storage Request | Removed | " + item[0].requestId
      );
      console.log("Removed", ...item);
      console.groupEnd();
    }

    this.storage.push({ requestId, request });
    sessionStorage.setItem(
      "__Storage__Request__",
      JSON.stringify(this.storage)
    );
    console.groupCollapsed("Storage Request | Added | " + requestId);
    console.log("Added", { requestId, request });
    console.groupEnd();
  }
}
