import { BitApiModule } from "api-module/api.core";

export const PutUserPassword = async (
  userId: string,
  password: string
): Promise<Response> => {
  return await new BitApiModule.ApiService({
    headers: { "Content-Type": "application/json" },
    relativeUrl: `user/${userId}/password`,
    body: JSON.stringify({ password }),
    method: "PUT",
    query: [
      {
        userId,
      },
    ],
  }).request();
};

export const PostUserSecretKey = async (userId: string): Promise<Response> => {
  return await new BitApiModule.ApiService({
    relativeUrl: `user/${userId}/secret`,
    method: "POST",
    query: [
      {
        userId,
      },
    ],
  }).request();
};

export const RemoveUserSession = async (userId: string): Promise<Response> => {
  return await new BitApiModule.ApiService({
    relativeUrl: `user/${userId}/session`,
    method: "DELETE",
    query: [
      {
        userId,
      },
    ],
  }).request();
};

export const UserMetricsBySelectedSources = async (
  userId: string,
  sources: string[],
  from: string,
  till: string
): Promise<Response> => {
  return await new BitApiModule.ApiService({
    headers: { "Content-Type": "application/json" },
    relativeUrl: `user/${userId}/metric`,
    body: JSON.stringify({ sources, from, till }),
    method: "POST",
    query: [
      {
        userId,
      },
    ],
  }).request();
};

export const DeactivatedUsers = async (
  users: string[],
  method: string
): Promise<Response> => {
  return await new BitApiModule.ApiService({
    headers: { "Content-Type": "application/json" },
    relativeUrl: `user/batch/deactivated`,
    body: JSON.stringify({ users }),
    method,
  }).request();
};

export const ExpirationUsers = async (
  users: string[],
  value: string
): Promise<Response> => {
  return await new BitApiModule.ApiService({
    headers: { "Content-Type": "application/json" },
    relativeUrl: `user/batch/expiration`,
    body: JSON.stringify({ users, expiration: { value } }),
    method: "POST",
  }).request();
};

export const CheckSession = async (): Promise<Response> => {
  return await new BitApiModule.ApiService({ relativeUrl: "auth" }).request();
};

export const GetUserSecretKeys = async (userId: string): Promise<Response> => {
  return await new BitApiModule.ApiService({
    relativeUrl: `user/${userId}/secret`,
    query: [
      {
        userId,
      },
    ],
  }).request();
};

export const DeleteUserSecretKey = async (
  userId: string,
  key: string
): Promise<Response> => {
  return await new BitApiModule.ApiService({
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ secret: key }),
    relativeUrl: `user/${userId}/secret`,
    method: "DELETE",
    query: [
      {
        userId,
      },
    ],
  }).request();
};

export const PostDeactivatedUser = async (
  userId: string
): Promise<Response> => {
  return await new BitApiModule.ApiService({
    relativeUrl: `user/${userId}/deactivated`,
    method: "POST",
    query: [
      {
        userId,
      },
    ],
  }).request();
};

export const PutUserExpiration = async (
  userId: string,
  value: string
): Promise<Response> => {
  return await new BitApiModule.ApiService({
    headers: { "Content-Type": "application/json" },
    relativeUrl: `user/${userId}/expiration`,
    body: JSON.stringify({ value }),
    method: "PUT",
    query: [
      {
        userId,
      },
    ],
  }).request();
};

export const GetUser = async (userId: string): Promise<Response> => {
  return await new BitApiModule.ApiService({
    relativeUrl: `user/${userId}/`,
    query: [
      {
        userId,
      },
    ],
  }).request();
};

export const DeleteDeactivatedUser = async (
  userId: string
): Promise<Response> => {
  return await new BitApiModule.ApiService({
    relativeUrl: `user/${userId}/deactivated`,
    method: "DELETE",
    query: [
      {
        userId,
      },
    ],
  }).request();
};

export const PutChangeComment = async (
  userId: string,
  comment: string
): Promise<Response> => {
  return await new BitApiModule.ApiService({
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ value: comment }),
    relativeUrl: `user/${userId}/comment/`,
    method: "PUT",
    query: [
      {
        userId,
      },
    ],
  }).request();
};

export const CreateNewUser = async (login: string): Promise<Response> => {
  return await new BitApiModule.ApiService({
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ login }),
    relativeUrl: `user`,
    method: "POST",
  }).request();
};
