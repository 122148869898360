import { SecretKeyModule } from "./secret";
import { IApiProperties, headers, method, body } from "../types/type";

export class PropertiesModule {
  validate(
    isAuth: boolean,
    headers: headers,
    method: method,
    body: body
  ): IApiProperties {
    const properties: IApiProperties = {};

    try {
      if (!isAuth) {
        properties.headers = {
          "X-API-Secret": new SecretKeyModule().getSecret()!,
        };
      }
    } catch (e) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = "#/login";
    }

    if (headers) {
      properties.headers = { ...properties.headers, ...headers };
    }

    if (method) {
      properties.method = method;
    }

    if (body) {
      properties.body = body;
    }

    return properties;
  }
}
