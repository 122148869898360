import { ProductionURL, DevelopmentURL } from "../types/type";

export class ConnectModule {
  private static development: DevelopmentURL;
  private static production: ProductionURL;
  private static _instance: ConnectModule;

  constructor(production?: ProductionURL, development?: DevelopmentURL) {
    if (ConnectModule._instance) return ConnectModule._instance;

    ConnectModule._instance = this;
    ConnectModule.development = development!;
    ConnectModule.production = production!;
  }

  public validate(): ProductionURL | DevelopmentURL {
    return process.env.NODE_ENV !== "production"
      ? ConnectModule.production
      : ConnectModule.development;
  }
}
