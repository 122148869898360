import { BitApiModule } from "api-module/api.core";
import { SECRET } from "./source/constants/constants";
import { NavigationGuardNext, Route } from "vue-router";
import { router, userRouter } from "./router";
import App from "./App.vue";
import UserApp from "./UserApp.vue";
import Vue from "vue";

new BitApiModule.ConnectModule("https://zen-api-test.xneo.io/1/", "/1/");
const secret = new BitApiModule.SecretKeyModule(SECRET);

Vue.config.productionTip = false;

type VueRouterHotReload = any;
type Path = string;
type Role = string;

/**
 * Протекция роутинга и проверка секретного ключа
 **/
router.beforeEach(
  ({ path, name }: Route, _: Route, next: NavigationGuardNext) => {
    return next((): Path => {
      if (path !== "/login") {
        if (secret.getSecret()! === undefined) return "/login";

        const access: boolean = JSON.parse(sessionStorage.getItem("id")!).some(
          (el: Role): boolean => el === "admin"
        );

        if (name === "userconfig") {
          return path;
        }

        return access ? path : "/";
      }
      return path;
    });
  }
);

userRouter.beforeEach(
  ({ path }: Route, _: Route, next: NavigationGuardNext) => {
    return next((): Path => {
      if (path !== "/login") {
        if (secret.getSecret()! === undefined) return "/login";
        return path;
      }
      return path;
    });
  }
);

/**
 * Мантирование приложения
 **/
try {
  new Vue({
    router,
    render: (h: VueRouterHotReload) => h(App),
  }).$mount("#app");

  new Vue({
    router: userRouter,
    render: (h: VueRouterHotReload) => h(UserApp),
  }).$mount("#user");
} catch (e) {
  console.log(e);
}
