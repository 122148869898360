import { LocalStorageKey } from "../types/type";

export class SecretKeyModule {
  private static secret: LocalStorageKey;
  private static _instance: SecretKeyModule;

  constructor(secret?: LocalStorageKey) {
    if (SecretKeyModule._instance) return SecretKeyModule._instance;

    SecretKeyModule._instance = this;
    SecretKeyModule.secret = secret!;
  }

  public getSecret(): string | undefined {
    return JSON.parse(localStorage.getItem(SecretKeyModule.secret)!);
  }
}
